<template>
  <span ref="cnr" class="bin-item">
    <img :src="imgSrc" />
  </span>
</template>

<script>
import { computed } from '@vue/reactivity'
import { useStore } from "vuex"

export default {
  setup() {
    const store = useStore()
    return {
      setBinDrop: (val) => store.commit("binDrop", val),
      setCanDrop: (val) => store.commit("canDrop", val),
      canDrop: computed(() => store.candrop),
    }
  },
  mounted() {
    // this.$refs.cnr.addEventListener("touchstart", e => {
    //   e.preventDefault()
    //   this.setBinDrop(this.imgSrc)
    // })
    
    // this.$refs.cnr.addEventListener("mousedown", e => {
    //   e.preventDefault()
    //   this.setBinDrop(this.imgSrc)
    // })
    
    this.$refs.cnr.addEventListener("click", e => {
      e.preventDefault()
      this.setBinDrop(this.imgSrc)
    })
  },
  props: [
    "imgSrc"
  ],
}
</script>

<style>

</style>