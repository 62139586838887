<template>
  <div id="Canvas" ref="container"></div>
</template>

<script>
import { ref } from '@vue/reactivity'
import useWebSocket from "@/composables/useWebSocket"
import Konva from "konva"

export default {
  name: 'Canvas',
  setup() {
    const { socket, makeSocket } = useWebSocket("/canvas")

    return {
      stage: null,
      layers: [],
      socket,
      makeSocket,
      party: ref({}),
      reconnectTmt: null,
      pingTmt: null,
    }
  },
  watch: {
  socket(next, prev) {
    if (prev) {
      prev.removeEventListener("message", this.handleSocketMessage)
    }
    if (!next) { return }
    next.addEventListener("message", this.handleSocketMessage)
  }
},
  mounted() {
    this.bindSocketListeners(this.socket)
    this.stage = new Konva.Stage({
      container: this.$refs.container,
      width: 1920,
      height: 1080,
    })
    this.layers[0] = new Konva.Layer()
    this.stage.add(this.layers[0])
  },
  beforeUnmount() {
    clearTimeout(this.reconnectTmt)
  },
  methods: {
    bindSocketListeners(ws) {
      ws.addEventListener("message", this.handleSocketMessage)
    },
    handleSocketMessage(e) {
      const msg = JSON.parse(e.data)
      console.log("Socket Received:", msg)
      if (msg.type === "member") { this.handleMemberMessage(msg) }
    },
    handleMemberMessage(msg) {
      switch(msg.method) {
        case "post":
          return this.addMember(msg.payload)
        case "put":
          return this.transformMember(msg.payload)
        case "delete":
          return this.deleteMember(msg.payload)
        default:
          console.warn(`Unknown method: ${msg.method}`)
      }
    },
    async addMember(data) {
      const { workId, children, groupAttrs } = data
      if (this.party[workId]) { this.party[workId].destroy() }

      const group = new Konva.Group()
      const parts = await Promise.all(children.map(c => {
        return new Promise(resolve => {
          const { image: src, ...rest } = c
          Konva.Image.fromURL(c.image, image => {
            image.setAttrs(rest)
            resolve(image)
          })
        })
      }))
      parts.forEach(node => group.add(node))
      group.setAttrs(groupAttrs)
      this.layers[0].add(group)
      this.party[workId] = group
    },
    async transformMember(data) {
      return this.party[data.workId]?.setAttrs(data.groupAttrs)
    },
    deleteMember(data) {
      const work = this.party[data.workId]
      if (work) { work.destroy() }
    },
  }
}
</script>

<style>
#Canvas {
  background: url("/scenes/phoenix.jpg");
  background-size: cover;
  background-position: center bottom;
  position: relative;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1080px;
}
</style>