<template>
  <main class="stage">
    <div class="canvas-wrapper">
      <div id="Canvas" ref="container"></div>
    </div>
    <nav :style="{opacity: dragging ? 0.5 : 1}">
      <router-link to="/edit"><button>NEW</button></router-link>
    </nav>
  </main>
</template>

<script>
import { ref, computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import Konva from "konva"

const btnSize = 100

export default {
  name: 'Canvas',
  setup() {
    const store = useStore()
    const socket = computed(() => store.state.socket)
    const myWorks = computed(() => store.state.myWorks)
    return {
      stage: null,
      myWorks,
      socket,
      layers: [],
      setMyWorks: (val) => store.commit("myWorks", val),
      party: ref({}),
      dragging: ref(false),
    }
  },
  mounted() {
    this.stage = new Konva.Stage({
      container: this.$refs.container,
      width: 1920,
      height: 1080,
    })
    this.layers[0] = new Konva.Layer()
    this.stage.add(this.layers[0])

    this.makeGroups()

    this.fitStageIntoParentContainer()
    window.addEventListener("resize", this.fitStageIntoParentContainer)
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.fitStageIntoParentContainer)
  },
  methods: {
    fitStageIntoParentContainer() {
      const sceneWidth = 1920
      const sceneHeight = 1080
      const { width, height } = this.$refs.container.getBoundingClientRect()
      const stage = this.stage

      var scaleWidth = width / sceneWidth;
      var scaleHeight = height / sceneHeight;

      stage.width(1920 * scaleWidth);
      stage.height(1080 * scaleHeight);
      stage.scale({ x: scaleWidth, y: scaleHeight });
    },
    async makeGroups() {
      const { myWorks, partsFromChildren } = this
      const allGroups = await Promise.all(Object.keys(myWorks).map(k => {
        return new Promise(async resolve => {
          const { children, groupAttrs } = myWorks[k]
          const group = new Konva.Group()
          const parts = await partsFromChildren(children)
          parts.forEach(node => group.add(node))
          
          const btnEdit = this.btnEdit({ x: 0, y: -btnSize/2-10 })
          btnEdit.on("click touchend", e => this.$router.push(`/edit?id=${k}`))
          
          const btnDelete = this.btnTrash({ x: btnSize+10, y: -btnSize/2-10 })
          btnDelete.on("click touchend", e => this.deleteMember(k))

          group.add(btnEdit)
          group.add(btnDelete)
          group.setAttrs({...groupAttrs, draggable: true})
          group.on("dragstart", () => this.setDragging(true))
          group.on("dragend", () => {
            this.putMember(k, { ...group.getAttrs() })
            this.setDragging(false)
          })
          this.party[k] = group
          resolve(group)
        })
      }))
      allGroups.forEach(group => this.layers[0].add(group))
    },
    btnEdit(attrs) {
      const group = new Konva.Group()
      const bg = new Konva.Rect({ width: btnSize, height: btnSize / 2, fill: "white" })
      const text = new Konva.Text({ text: "edit", fontSize: btnSize / 3, align: "center", x: 0 })
      group.add(bg)
      group.add(text)
      group.setAttrs(attrs)
      return group
    },
    btnTrash(attrs) {
      const group = new Konva.Group()
      const bg = new Konva.Rect({ width: btnSize, height: btnSize / 2, fill: "white" })
      const text = new Konva.Text({ text: "trash", fontSize: btnSize / 3, align: "center", x: 0 })
      group.add(bg)
      group.add(text)
      group.setAttrs(attrs)
      return group
    },
    setDragging(val) {
      this.dragging = val
    },
    async partsFromChildren(children) {
      const parts = await Promise.all(children.map(c => {
        return new Promise(resolve => {
          const { image: src, ...rest } = c
          Konva.Image.fromURL(c.image, image => {
            image.setAttrs(rest)
            resolve(image)
          })
        })
      }))
      return parts
    },
    async putMember(workId, groupAttrs) {
      const { myWorks } = this
      this.socket.send(JSON.stringify({
        type: "member",
        method: "put",
        payload: {
          workId,
          groupAttrs,
        }
      }))
      this.setMyWorks({
        ...myWorks,
        [workId]: {
          ...myWorks[workId],
          groupAttrs,
        }
      })
    },
    async deleteMember(workId) {
      this.socket.send(JSON.stringify({
        type: "member",
        method: "delete",
        payload: {
          workId
        }
      }))
      this.party[workId].destroy()
      const newWorks = Object.assign({}, this.myWorks)
      delete newWorks[workId]
      this.setMyWorks(newWorks)
    },
  }
}
</script>

<style>
main.stage {
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  display: flex;
  background: rgba(30,30,30,1);
}

main.stage .canvas-wrapper {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  margin: auto;
  box-shadow: 0px 0px 10px 5px rgb(0,0,0,0.2);
}

main.stage #Canvas {
  background: url("/scenes/phoenix.jpg");
  background-size: cover;
  background-position: center bottom;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

main.stage nav {
  width: 4rem;
  height: 4rem;
  padding: 0.5rem;
  box-sizing: border-box;
  display: flex;
}

main.stage nav a {
  color: black;
  text-decoration: none;
  margin: auto;
}
</style>