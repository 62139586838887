import { createStore, createLogger } from "vuex"
import VuexPersistence from "vuex-persist"

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export const store = createStore({
  plugins: [createLogger(), vuexLocal.plugin],
  state() {
    return {
      binDrop: null,
      canDrop: false,
      socket: null,
      myWorks: {},
      customParts: [],
    }
  },
  mutations: {
    binDrop(state, p) {
      state.binDrop = p
    },
    canDrop(state, p) {
      state.canDrop = p
    },
    socket(state, p) {
      state.socket = p
    },
    myWorks(state, p) {
      state.myWorks = p
    },
    customParts(state, p) {
      state.customParts = p
    },
  }
})