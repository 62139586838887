import Creator from "../components/Creator.vue"
import Editor from "../components/Editor.vue"
import Stage from "../components/Stage.vue"
import Canvas from "../components/Canvas.vue"

export default [
  {
    path: "/",
    component: Creator,
    redirect: (to, from) => {
      console.log("boop")
      const works = Object.keys(JSON.parse(window.localStorage.getItem("vuex"))?.myWorks||{})
      if (!from && works?.length) { return "/stage" }
      else { return "/edit" }
    },
    children: [
      {
        path: "/edit",
        component: Editor,
      },
      {
        path: "/stage",
        component: Stage,
      },
    ]
  },
  {
    path: "/scene/space-barn",
    component: Canvas
  },
]