<template>
  <div>
    <input
      ref="fileInput"
      hidden
      :disabled="disabled"
      @change="handleChange"
      type="file"
      accept="image/png, image/jpeg">
    <button :class="{disabled}" @click="() => $refs.fileInput.click()">UPLOAD</button>
    <p v-if="error">{{error}}</p>
  </div>
</template>

<script>
import { v4 } from "uuid"
import { computed } from "vue"
import { useStore } from "vuex"

export default {
  setup() {
    const store = useStore()
    const customParts = computed(() => store.state.customParts)
    return {
      blob: null,
      error: '',
      disabled: false,
      customParts,
      setCustomParts: (val) => store.commit("customParts", val),
    }
  },
  methods: {
    async handleChange(e) {
      this.disabled = true
      this.error = ''
      try {
        this.blob = e.target.files[0]
        if (this.blob.size > 2000000) { throw "Must be < 2MB" }
        const key = `${v4()}_${this.blob.name.replace(' ', '')}`
        this.error = 'loading...'
        const url = await this.getPresign(key)
        if (!url) { throw "Something went wrong..." }
        await this.upload(url)
        const finUrl = `https://thump-creator.s3.us-west-1.amazonaws.com/Submissions/${key}`
        const newCustomParts = [...this.customParts, finUrl]
        this.setCustomParts(newCustomParts)
        this.error = ''
      } catch(e) {
        this.error = e
      } finally {
        this.disabled = false
      }
    },
    async getPresign(object_key) {
      try {
        const res = await fetch("https://7dz36pz5rc.execute-api.us-west-1.amazonaws.com/submissions", {
          method: "PUT",
          body: JSON.stringify({ object_key })
        })
        const url = await res.text()
        return url
      } catch(err) {
        console.error(err)
        return null
      }
    },
    async upload(url) {
      try {
        const res = await fetch(url, {
          method: "PUT",
          body: this.blob,
        })
        const finRes = await res.text()
        return finRes
      } catch(err) {
        console.error(err)
        return null
      }
    }
  }
}
</script>

<style scoped>
button.disabled {
  opacity: 0.5;

}
</style>