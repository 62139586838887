<template>
  <router-view></router-view>
</template>

<script>
import { computed } from "vue"
import { useStore } from "vuex"
import Editor from "./Editor.vue"
import Stage from "./Stage.vue"
import useWebSocket from "@/composables/useWebSocket"

export default {
  name: "Creator",
  setup() {
    const store = useStore()
    const myWorks = computed(() => store.state.myWorks)
    const { socket, makeSocket } = useWebSocket("/creator")
    return {
      socket,
      makeSocket,
      setGlobalCreatorSocket: (val) => store.commit("socket", val),
      myWorks,
    }
  },
  watch: {
    socket(next, prev) {
      this.setGlobalCreatorSocket(next)
      if (prev) {
        prev.removeEventListener("open", this.handleSocketOpen)
        prev.removeEventListener("message", this.handleSocketMessage)
      }
      if (!next) { return }
      this.bindSocketListeners(next)
    }
  },
  mounted() {
    this.setGlobalCreatorSocket(this.socket)
    this.bindSocketListeners(this.socket)
  },
  methods: {
    bindSocketListeners(ws) {
      ws.addEventListener("open", this.handleSocketOpen)
      ws.addEventListener("message", this.handleSocketMessage)
    },
    handleSocketOpen() {
      this.hydrateCanvas()
    },
    handleSocketMessage(e) {
      const msg = JSON.parse(e.data)
      console.log("Socket Received:", msg)
      if (msg.type === "canvas:hydrate") { this.hydrateCanvas() }
    },
    hydrateCanvas() {
      Object.keys(this.myWorks).forEach(workId => {
        const work = this.myWorks[workId]
        this.socket.send(JSON.stringify({
          type: "member",
          method: "post",
          payload: {
            ...work,
            workId,
          }
        }))
      })
    },
  },
  components: {
    Editor,
    Stage,
  }
}
</script>

<style>
body {
  margin: 0;
}
</style>
