<template>
  <main id="Editor">
    <div ref="container"></div>
    <div :style="{display: publishing ? 'block' : 'none'}" ref="publishCnr"></div>
    <button class="save" v-if="socket" @click="publish">SAVE</button>
    <div v-if="publishing" class="overlay">publishing...</div>
    <span v-if="selected" @click="trash" class="util trash"><img src="/vector/trash.svg" /></span>
    <span v-if="selected" @click="sendToBack" class="util send-to-back"><img src="/vector/send-to-back.svg" /></span>
  </main>
  <Bin />
</template>

<script>
import Konva from "konva"
import { computed, ref, reactive } from "vue"
import { useStore } from "vuex"
import { v4 as uuidv4 } from "uuid"
import Bin from './Bin.vue'

export default {
  setup() {
    const store = useStore()
    const binDrop = computed(() => store.state.binDrop)
    const socket = computed(() => store.state.socket)
    const myWorks = computed(() => store.state.myWorks)

    return {
      stage: null,
      transformer: null,
      layers: [],
      socket,
      binDrop,
      setBinDrop: (val) => store.commit("binDrop", val),
      myWorks,
      setMyWorks: (val) => store.commit("myWorks", val),
      publishing: ref(false),
      selected: ref(null),
    }
  },
  watch: {
    binDrop(next) {
      if (next) {
        this.handleDrop()
        // window.addEventListener("touchend", this.handleTouchDrop)
        // window.addEventListener("mouseup", this.handleTouchDrop)
      } else {        
        // window.removeEventListener("touchend", this.handleTouchDrop)
        // window.removeEventListener("mouseup", this.handleTouchDrop)
      }
    }
  },
  mounted() {
    this.stage = new Konva.Stage({
      container: this.$refs.container,
      width: window.innerWidth,
      height: window.innerHeight,
    })
    this.layers[0] = new Konva.Layer()
    this.transformer = new Konva.Transformer({
      nodes: [],
      anchorStroke: 'red',
      anchorFill: 'yellow',
      anchorSize: 10,
      borderStroke: 'green',
      borderDash: [3, 3],
    })
    this.layers[0].add(this.transformer)
    this.stage.add(this.layers[0])

    const workId = this.$route.query['id']
    if (!workId) {
      console.log("make a new one")
      const newId = uuidv4()
      this.$router.replace({ query: { id: newId } })
      this.workId = newId
    } else {
      this.workId = workId
      const work = this.myWorks[workId]
      if (work) { this.loadExisting(work) }
      else { /* ... */ }
    }

    window.addEventListener("resize", this.resize)
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resize)
  },
  methods: {
    resize() {
      this.stage.width(window.innerWidth)
      this.stage.height(window.innerHeight)
    },
    async loadExisting(data) {
      const parts = await Promise.all(data.children.map(c => {
        return new Promise(resolve => {
          const { image: src, ...rest } = c
          Konva.Image.fromURL(c.image, image => {
            image.setAttrs(rest)
            image.on("mousedown touchstart", this.handleSelectImage)
            resolve(image)
          })
        })
      }))
      const tempGroup = new Konva.Group()
      parts.forEach(node => {
        tempGroup.add(node)
      })
      const gcr = tempGroup.getClientRect()
      const { width: stageWidth, height: stageHeight } = this.stage.attrs
      tempGroup.move({ x: stageWidth / 2 - gcr.width / 2, y: stageHeight / 2 - gcr.height / 2 })
      this.layers[0].add(tempGroup)
      parts.forEach(node => {
        const { x, y } = node.getAbsolutePosition()
        node.moveTo(this.layers[0])
        node.position({ x, y })
        node.draggable(true)
      })
      tempGroup.destroy()
    },
    trash() {
      this.selected.destroy()
      this.selected = null
      this.transformer.nodes([])
    },
    sendToBack() {
      this.selected.zIndex(0)
    },
    makeImage(src) {
      Konva.Image.fromURL(src, image => {
        this.layers[0].add(image)
        const { width, height } = image.getClientRect()
        const x = this.stage.attrs.width / 2 - width / 2
        const y = this.stage.attrs.height / 2 - height / 2
        image.position({ x, y })
        image.draggable(true)
        image.on("mousedown touchstart", this.handleSelectImage)
      })
    },
    handleSelectImage(e) {
      e.target.zIndex(this.layers[0].children.length - 1)
      this.transformer.zIndex(this.layers[0].children.length - 1)
      this.transformer.nodes([e.target])
      this.selected = e.target
    },
    handleTouchDrop(e) {
      // this.stage.setPointersPositions(e)
      this.makeImage(this.binDrop)
      this.setBinDrop(null)
    },
    handleDrop(e) {
      this.makeImage(this.binDrop)
      this.setBinDrop(null)
    },
    async publish() {
      this.publishing = true
      const group = this.groupFromParts()
      const { myWorks, workId } = this

      const groupAttrs = myWorks[workId] ? myWorks[workId].groupAttrs : this.makeGroupAttrs(group)
      const serialized = this.serializeNodes(group)
      this.socket.send(JSON.stringify({
        type: "member",
        method: "post",
        payload: {
          children: serialized,
          workId,
          groupAttrs,
        }
      }))
      this.setMyWorks({ ...this.myWorks, [this.workId]: { children: serialized, groupAttrs } })
      this.publishing = false
      this.$router.push("/stage")
    },
    makeGroupAttrs(group) {
      const gcr = group.getClientRect()
      return {
        x: (1920 - gcr.width) * Math.random(),
        y: 1080 - gcr.height - 1080 * 0.2 * Math.random(),
      }
    },
    serializeNodes(group) {
      const children = []
      group.children.forEach(c => {
        const src = c.attrs.image.src
        const image = src.match(window.origin)
          ? src.replace(window.origin, '')
          : src
        children.push({ ...c.attrs, image, draggable: false, })
      })
      return children
    },
    groupFromParts() {
      const group = new Konva.Group()
      this.layers[0].children.forEach(c => {
        if (c === this.transformer) { return }
        c !== this.transformer ? group.add(c.clone()) : null
      })

      let minX = 0
      let minY = 0
      let maxX = 0
      let maxY = 0

      group.getChildren().forEach((n,i) => {
        const { x: nearX, y: nearY, width, height } = n.absolutePosition()
        const farX = nearX + width
        const farY = nearY + height

        if (i === 0) {
          minX = nearX
          minY = nearY
          maxX = farX
          maxY = farY
        } else {
          if (nearX < minX) { minX = nearX }
          if (nearY < minY) { minY = nearY }
          if (farX > maxX) { maxX = farX }
          if (farY > maxY) { maxY = farY }
        }
      })

      group.getChildren().forEach(n => {
        n.move({ x: -minX, y: -minY })
      })

      return group
    },
    async flatImageFromBounds(node) {
      const clientRect = node.getClientRect()
      const publishCnr = this.$refs.publishCnr
      const publishStage = new Konva.Stage({
        container: publishCnr,
        width: clientRect.width,
        height: clientRect.height,
      })
      const publishLayer = new Konva.Layer()
      publishLayer.add(node)
      publishStage.add(publishLayer)
      const image = await publishStage.toDataURL()
      return image
    },
    async publishToImgur(image) {
      const formData = new FormData()
      formData.append("image", image.replace(/^data:image\/(png|jpe?g|gif|svg);base64,/, ""))
      const res = await fetch("https://api.imgur.com/3/image", {
        method: "post",
        headers: {
          "Authorization": "Client-ID d4e8e8e1b66c363"
        },
        body: formData
      })
      const json = await res.json()
      return json
    },
    async submitImgurImage(group) {
      const image = await this.flatImageFromBounds(group)
      const json = await this.publishToImgur(image)

      // console.log(json, json.data.link)
      this.socket.send(JSON.stringify({
        type: "member",
        payload: {
          src: json.data.link
        }
      }))
    },
  },
  components: {
    Bin
  }
}
</script>

<style>
#Editor {
  background: url('/scenes/spotlight.jpg');
  background-position: center bottom;
  background-size: cover;
}
button.save {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 4rem;
  height: 4rem;
}
.overlay {
  position: fixed;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(0,0,0,0.25);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
nav {
  position: fixed;
  top: 0; right: 0;
}

.util {
  position: fixed;
  width: 3rem;
  height: 3rem;
  padding: 0.25rem;
  box-sizing: border-box;
  background: rgb(180,180,180);
  border-radius: 50%;
}

.util img {
  width: 100%;
  height: auto;
}

.trash {
  top: 1rem; left: 1rem;
}

.send-to-back {
  top: 1rem; left: 5rem;
}
</style>