<template>
  <div id="Bin">
    <ul class="tabs">
      <li @click="() => active = 'bodies'" :class="{ active: active === 'bodies' }">Bodies</li>
      <li @click="() => active = 'accents'" :class="{ active: active === 'accents' }">Accents</li>
      <li @click="() => active = 'props'" :class="{ active: active === 'props' }">Props</li>
      <li @click="() => active = 'stickers'" :class="{ active: active === 'stickers' }">Stickers</li>
      <li @click="() => active = 'custom'" :class="{ active: active === 'custom' }">Custom</li>
    </ul>
    <div v-if="active === 'bodies'" class="drawer">
      <BinItem imgSrc="/bin-parts/bodies/arm.png" />
      <BinItem imgSrc="/bin-parts/bodies/ben-pancakes.png" />
      <BinItem imgSrc="/bin-parts/bodies/Blob-guy.png" />
      <BinItem imgSrc="/bin-parts/bodies/comp-head-1.png" />
      <BinItem imgSrc="/bin-parts/bodies/comp-head-2.png" />
      <BinItem imgSrc="/bin-parts/bodies/Disco-1.png" />
      <BinItem imgSrc="/bin-parts/bodies/Disco-2.png" />
      <BinItem imgSrc="/bin-parts/bodies/Disco-3.png" />
      <BinItem imgSrc="/bin-parts/bodies/Disco-5.png" />
      <BinItem imgSrc="/bin-parts/bodies/Disco-6.png" />
      <BinItem imgSrc="/bin-parts/bodies/Disco-7.png" />
      <BinItem imgSrc="/bin-parts/bodies/henry-solomon.png" />
      <BinItem imgSrc="/bin-parts/bodies/henry-w.png" />
      <BinItem imgSrc="/bin-parts/bodies/lips.png" />
      <BinItem imgSrc="/bin-parts/bodies/lucas-body-2.png" />
      <BinItem imgSrc="/bin-parts/bodies/lucas-body-1.png" />
      <BinItem imgSrc="/bin-parts/bodies/paul.png" />
      <BinItem imgSrc="/bin-parts/bodies/seal-head.png" />
      <BinItem imgSrc="/bin-parts/bodies/Shaq-head.png" />
      <BinItem imgSrc="/bin-parts/bodies/suit.png" />
      <BinItem imgSrc="/bin-parts/bodies/yak.png" />
      <BinItem imgSrc="/bin-parts/bodies/fish.png" />
    </div>
    <div v-if="active === 'accents'" class="drawer">
      <BinItem imgSrc="/bin-parts/accents/glasses-2.png" />
      <BinItem imgSrc="/bin-parts/accents/googly-eye.png" />
      <BinItem imgSrc="/bin-parts/accents/hat.png" />
      <BinItem imgSrc="/bin-parts/accents/hello.png" />
      <BinItem imgSrc="/bin-parts/accents/quote.png" />
      <BinItem imgSrc="/bin-parts/glasses.png" />
    </div>
    <div v-if="active === 'props'" class="drawer">
      <BinItem imgSrc="/bin-parts/props/car-boys.png" />
    </div>
    <div v-if="active === 'stickers'" class="drawer">
      <BinItem imgSrc="/bin-parts/stickers/crypto-goose.png" />
      <BinItem imgSrc="/bin-parts/stickers/i-voted.png" />
    </div>
    <div v-if="active === 'custom'" class="drawer">
      <Uploader />
      <BinItem v-for="p in customParts" v-bind:key="p" :imgSrc="p" />
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue"
import { useStore } from "vuex"
import BinItem from "./BinItem"
import Uploader from "./Uploader"

export default {
  setup() {
    const store = useStore()
    const customParts = computed(() => store.state.customParts)
    return {
      active: ref("bodies"),
      customParts
    }
  },
  components: {
    BinItem,
    Uploader,
  }
}
</script>

<style>
#Bin {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: calc(100% - 4rem);
}
#Bin .drawer {
  display: flex;
  width: 100%;
  overflow: scroll;
  background: rgba(255,255,255,0.75);
  height: 5rem;
}
#Bin ul.tabs {
  padding: 0;
  margin: 0;
  list-style-type: none;
  border-bottom: 1px solid black;
  width: 100%;
  overflow: scroll;
  display: flex;
  justify-content: flex-start;
  background: rgba(255,255,255,0.75);
}
#Bin ul.tabs li {
  padding: 0.5rem 0.75rem;
}
#Bin ul.tabs li.active {
  color: white;
  background:rgba(0,0,0,0.75)
}
.bin-item {
  display: inline-flex;
  min-width: 5rem;
  height: 5rem;
  border-radius: 0.5rem;
}

.bin-item img {
  max-width: calc(100% - 2rem);
  max-height: calc(100% - 2rem);
  height: auto;
  width: auto;
  margin: auto;
}
</style>