import { reactive, toRefs } from "vue"
import { apiBaseUrl, wsProtocol } from '../config'

const reconnectTmtDur = 5000
const pingTmtDur = 10000

export default function useWebSocket(ns) {
  const state = reactive({ socket: makeSocket(ns) })
  const { socket } = toRefs(state)
  let pingTmt = null
  let reconnectTmt = null

  window.addEventListener("focus", handleWindowFocus)

  function sendPing() {
    socket.value.send(JSON.stringify({ type: "ping" }))
  }

  function handleOpen() {
    console.log("socket opened")
    clearTimeout(reconnectTmt)
    refreshPingTmt()
  }

  function handleMessage() {
    refreshPingTmt()
  }
  
  function handleError(e) {
    refreshReconnectTmt()
    console.error("socket error:", e)
    e.target.close()
  }

  function handleClose(e) {
    console.log("socket closed")
    unbindSocket(e.target)
    refreshReconnectTmt()
  }

  function makeSocket(ns) {
    clearTimeout(reconnectTmt)
    const ws = new WebSocket(`${wsProtocol}://${apiBaseUrl}${ns}`)
    ws.addEventListener("open", handleOpen)
    ws.addEventListener("message", handleMessage)
    ws.addEventListener("close", handleClose)
    ws.addEventListener("error", handleError)
    return ws
  }

  function unbindSocket(ws) {
    clearTimeout(pingTmt)
    ws.removeEventListener("open", handleOpen)
    ws.removeEventListener("message", handleMessage)
    ws.removeEventListener("close", handleClose)
    ws.removeEventListener("error", handleError)
  }

  function refreshReconnectTmt() {
    clearTimeout(reconnectTmt)
    reconnectTmt = setTimeout(refreshSocket, reconnectTmtDur)
  }

  function refreshPingTmt() {
    clearTimeout(pingTmt)
    pingTmt = setTimeout(sendPing, pingTmtDur)
  }

  function handleWindowFocus() {
    if (socket.value?.readyState < 2) { socket.value.close() }
    refreshSocket()
  }

  function refreshSocket() {
    socket.value = makeSocket(ns)
  }
  
  return { socket, makeSocket }
}